import React from 'react';
import '../styles/components/Footer.css';

const Footer = () => {
    return (
        <div className="Footer">
            <p className="Footer-title">
                Platzi Conf Merch
            </p>
            <p className="Footer-copy">
                Todos los Izquierdos Reservados
            </p>
        </div>
    );
}

export default Footer;